<template>
  <MainHeader />
  <main class="content">
    <RouterView />
  </main>
  <footer>
    <FooterView />
  </footer>
</template>

<script>

import MainHeader from './components/Shared/MainHeader.vue'
import FooterView from './components/Shared/FooterView.vue'

import './utils/arrayExtensions'

export default {
  name: 'App',
  components: {
    MainHeader,
    FooterView
  },
  created() {
    this.id = this.$route.id
  },
  data() {
    return {
      id: null,
    }
  }
}
</script>

<style>
html, body {
  height: 100%;
  margin: 0;
  background-color: #ffffff;
}
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'SF Pro Text';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.content {
  flex: 1;
}
@font-face {
  font-family: 'SF Pro Text';
  src: url('../public/fonts/SF-Pro-Text-Regular.otf') format('opentype');
  font-weight: 400; /* Regular */
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Text';
  src: url('../public/fonts/SF-Pro-Text-Medium.otf') format('opentype');
  font-weight: 500; /* Medium */
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Text';
  src: url('../public/fonts/SF-Pro-Text-Semibold.otf') format('opentype');
  font-weight: 600; /* Semibold */
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Text';
  src: url('../public/fonts/SF-Pro-Text-Bold.otf') format('opentype');
  font-weight: 700; /* Bold */
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Text';
  src: url('../public/fonts/SF-Pro-Text-Heavy.otf') format('opentype');
  font-weight: 800; /* Heavy */
  font-display: swap;
}
</style>
