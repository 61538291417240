<template>
    <h1>{{ $t('components.home.title') }}</h1>
    <p>{{ $t('components.home.text') }}</p>
    <div class="image-container">
        <picture>
            <source srcset="@/assets/home.avif" type="image/avif">
            <source srcset="@/assets/home.webp" type="image/webp">
            <img 
                src = "@/assets/home.png"
                height="300"
                alt="Home"
            />
        </picture>
    </div>
     <ul>
        <li>{{ $t('components.home.bulletOne') }}</li>
        <li>{{ $t('components.home.bulletTwo') }}</li>
        <li>{{ $t('components.home.bulletThree') }}</li>
    </ul>
 </template>
  
<style scoped>
h1 {
    padding-top: 40px;
    color: black;
    font-size: 22px;
    font-weight: bold;
    padding-left: 50px;
    padding-right: 50px;
}
p {
    font-size: 15px;
    padding-top: 40px;
    padding-left: 14px;
    padding-right: 14px;
}
.image-container {
    padding-top: 40px;
    padding-left: 14px;
    padding-right: 14px;
    text-align: center;
}
img {
    width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: contain;
}
ul {
    padding-top: 14px;
    list-style-type: none;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 40px;
}
li {
    padding-top: 26px;
    font-size: 16px;
    font-weight: bold;
}
</style>